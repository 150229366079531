import React, { useState, useEffect, useMemo } from 'react'
import { Field } from 'react-final-form'
import SearchInput from 'src/components/FormFields/SearchInput'
import {
  useClassGroupQuery,
  useAllInstructorsQuery,
  Viewer,
  User,
  ClassGroupQuery,
  Instructor,
} from 'src/graphql-generated'
import { Flex, Box } from 'src/components/Box'
import ErrorLabel from 'src/components/FormFields/ErrorLabel'
import { ProfileIcon } from 'src/components/ProfileIcon'
import Loading from 'src/components/Loading'
import { theme } from 'src/styles/theme'
import strings from 'src/strings'
import { RemoveIcon } from 'src/components/icons'
import Action from 'src/components/Action'
import { SmallBody } from 'src/components/text'
import { minuteToMs } from 'src/lib/time'

interface Props {
  classGroupId: number
  name: string
  optional?: boolean
  viewer: Viewer
}

const MemberResult: React.FunctionComponent<{
  member: User
}> = ({ member }) => (
  <Flex alignItems="center">
    {member && member.profileIconUrl && (
      <>
        <Box mr="3">
          <ProfileIcon user={member} />
        </Box>
        <SmallBody>{member && member.name ? member.name : ''}</SmallBody>
      </>
    )}
  </Flex>
)

const resolveMemberList = (
  data?: ClassGroupQuery,
  allInstructors?: Instructor[]
): User[] => {
  if (!data || !data.viewerClassGroup || !allInstructors) {
    return []
  }

  // Create a Map using instructor IDs as keys to ensure uniqueness
  const uniqueInstructors = new Map(
    [...data.viewerClassGroup.instructors, ...allInstructors]
      .filter(Boolean)
      .map(instructor => [instructor.id, instructor])
  )

  return Array.from(uniqueInstructors.values())
}

const REFRESH_DETAILS_INTERVAL_MS = minuteToMs(2)

const TeacherListField: React.FunctionComponent<Props> = ({
  name,
  classGroupId,
  viewer,
}) => {
  const { loading, data, error } = useClassGroupQuery({
    variables: { id: classGroupId },
  })

  const allInstructorsResult = useAllInstructorsQuery({
    pollInterval: REFRESH_DETAILS_INTERVAL_MS,
  })

  // Memoize allInstructors
  const allInstructors = useMemo(() => {
    return (
      (allInstructorsResult &&
        allInstructorsResult.data &&
        allInstructorsResult.data.allInstructors) ||
      []
    )
  }, [allInstructorsResult.data])

  // Memoize members to prevent unnecessary recalculations
  const members = useMemo(() => {
    return resolveMemberList(data, allInstructors).reduce<Record<number, User>>(
      (acc, s) => {
        acc[s.id] = s
        return acc
      },
      {}
    )
  }, [data, allInstructors])

  const [memberIds, setMemberIds] = useState<number[]>([])

  // Update memberIds only when data changes and is valid
  useEffect(() => {
    if (data && data.viewerClassGroup && data.viewerClassGroup.instructors) {
      setMemberIds(data.viewerClassGroup.instructors.map(({ id }) => id))
    }
  }, [data && data.viewerClassGroup && data.viewerClassGroup.instructors])

  return (
    members && (
      <Field name={name}>
        {field => {
          const fieldIds: number[] = field.input.value || [...memberIds]
          const removeMemberId = (id: number) =>
            field.input.onChange(fieldIds.filter(v => v !== id) as any)
          return (
            <Flex flexDirection="column">
              <SearchInput
                testId="teamMemberSearch"
                label={strings.manageCoTeacherModal.instructorLabel}
                placeholder={strings.manageCoTeacherModal.instructorPlaceholder}
                loading={loading}
                items={Object.values(members)
                  .filter(s => !fieldIds.includes(s.id))
                  .map(s => ({
                    value: s.name || '',
                    ...s,
                  }))}
                onSelect={(item, clearSelection) => {
                  field.input.onChange([...fieldIds, item.id] as any)
                  clearSelection()
                }}
                renderItem={(item, { isHighlighted }) => (
                  <Box
                    key={item.id}
                    p="3"
                    css={{
                      background: isHighlighted
                        ? theme.colors.skyBlue
                        : undefined,
                    }}
                  >
                    <MemberResult member={item} />
                  </Box>
                )}
              />
              {error && (
                <ErrorLabel>{strings.formValidation.genericError}</ErrorLabel>
              )}
              <Flex flexDirection="column">
                {loading && !data ? (
                  <Loading />
                ) : (
                  fieldIds.map(id => (
                    <Flex
                      py="4"
                      css={{
                        borderBottom: `1px solid ${theme.colors.lightGrey}`,
                      }}
                      key={`selected-${id}`}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MemberResult member={members[id]} />
                      {id !== viewer.id && (
                        <Box ml="2">
                          <Action onClick={() => removeMemberId(id)}>
                            <RemoveIcon width="18" />
                          </Action>
                        </Box>
                      )}
                    </Flex>
                  ))
                )}
              </Flex>
            </Flex>
          )
        }}
      </Field>
    )
  )
}

export default TeacherListField
