import React, { useCallback } from 'react'
import Downshift, { ControllerStateAndHelpers } from 'downshift'
import styled from 'src/styles/styled'
import { Box, Flex } from 'src/components/Box'
import { theme } from 'src/styles/theme'
import { shadow } from 'src/styles'
import { TextInput } from 'src/components/FormFields/TextInput'
import FieldLabel from 'src/components/FormFields/FieldLabel'
import Loading from 'src/components/Loading'
import { SmallBody } from 'src/components/text'
import strings from 'src/strings'

const MAX_RESULTS = 100 //Safety limit to keep the UI from being overwhelmed
const DEBOUNCE_THRESHOLD = 50 // Number of items that triggers debouncing

interface Props<Item extends { value: string }> {
  optional?: boolean
  placeholder?: string
  items: Item[]
  testId: string
  children?: undefined
  label?: string
  onSelect: (item: Item, clearSelection: () => void) => void
  loading?: boolean
  renderItem: (
    item: Item,
    meta: { isHighlighted: boolean; isSelected: boolean }
  ) => React.ReactNode
}

const ListContainer = styled.ul`
  position: absolute;
  z-index: 1;
  background: ${theme.colors.lightestGrey};
  padding: 0;
  margin: 0;
  width: 100%;
  top: 100%;
  max-height: 250px;
  overflow: auto;
  ${shadow.small};
`

const ListItem = styled.li`
  padding: 0;
  margin: 0;
  list-style: none;
  cursor: pointer;
`

const SearchResults: React.FunctionComponent = ({ children }) => {
  if (React.Children.count(children) === 0) {
    return (
      <Box p="3">
        <SmallBody>{strings.searchInput.noResults}</SmallBody>
      </Box>
    )
  }
  return <>{children}</>
}

const SearchInput = <T extends { value: string }>({
  items,
  label,
  loading = false,
  onSelect,
  optional = false,
  placeholder,
  testId,
  renderItem,
}: Props<T>) => {
  const [inputFocus, setInputFocus] = React.useState(false)
  const [debouncedValue, setDebouncedValue] = React.useState<string | null>(
    null
  )

  const debounceTimeout = React.useRef<NodeJS.Timeout>()

  const shouldDebounce = items.length >= DEBOUNCE_THRESHOLD

  const debouncedFilter = useCallback(
    (inputValue: string | null) => {
      if (!shouldDebounce) {
        setDebouncedValue(inputValue)
        return
      }

      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current)
      }

      debounceTimeout.current = setTimeout(() => {
        setDebouncedValue(inputValue)
      }, 300)
    },
    [shouldDebounce]
  )

  // Add cleanup effect
  React.useEffect(() => {
    // Cleanup function that runs on unmount
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current)
      }
    }
  }, []) // Empty dependency array since we only want this to run on unmount

  const filterItems = (items: T[], inputValue: string | null) => {
    const valueToUse = shouldDebounce ? debouncedValue : inputValue

    if (!valueToUse || valueToUse.length < 2) {
      return [] // Return empty array if no input or less than 2 chars
    }

    const lowercaseInput = valueToUse.toLowerCase()
    return items
      .filter(item => item.value.toLowerCase().includes(lowercaseInput))
      .slice(0, MAX_RESULTS)
  }

  const renderResults = ({
    getItemProps,
    highlightedIndex,
    selectedItem,
    inputValue,
  }: Pick<
    ControllerStateAndHelpers<T>,
    'getItemProps' | 'highlightedIndex' | 'selectedItem' | 'inputValue'
  >) => {
    const filteredItems = filterItems(items, inputValue)

    return filteredItems.map((item, index) => (
      <ListItem
        {...getItemProps({
          key: `${item.value}-${index}`,
          index,
          item,
        })}
      >
        {renderItem(item, {
          isHighlighted: highlightedIndex === index,
          isSelected: selectedItem === item,
        })}
      </ListItem>
    ))
  }

  return (
    <Downshift
      onChange={(item, stateHelpers) => {
        if (!item) {
          return
        }
        onSelect(item, stateHelpers.clearSelection)
      }}
      itemToString={item => (item ? item.value : '')}
      onInputValueChange={value => {
        debouncedFilter(value)
      }}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
        getRootProps,
      }) => (
        <Flex
          {...getRootProps()}
          css={{ position: 'relative' }}
          flexDirection="column"
        >
          {label && (
            <Box mb="4">
              <FieldLabel
                {...getLabelProps()}
                active={inputFocus || isOpen}
                optional={optional}
              >
                {label}
              </FieldLabel>
            </Box>
          )}
          <TextInput
            {...getInputProps({
              onBlur: () => setInputFocus(false),
              onFocus: () => setInputFocus(true),
              placeholder,
            })}
            data-cy={testId}
          />
          <ListContainer {...getMenuProps()}>
            {isOpen ? (
              loading ? (
                <Loading />
              ) : (
                <SearchResults>
                  {renderResults({
                    getItemProps,
                    inputValue,
                    selectedItem,
                    highlightedIndex,
                  })}
                </SearchResults>
              )
            ) : null}
          </ListContainer>
        </Flex>
      )}
    </Downshift>
  )
}

export default SearchInput
